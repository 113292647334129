import { Fragment } from 'react';

import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import { RouteProps } from '../../../../constants/routes';
import { CustomIcon } from '../../../custom-icon/custom-icon';
import { useMainMenuNavLink } from './use-main-menu-nav-link';

import style from '../../main-menu.module.css';

export const MainMenuNavLink = ({ path, icon, title, hasNotification }: RouteProps) => {
  const { messageCountQueryData } = useMainMenuNavLink();

  return (
    <Fragment key={path}>
      <NavLink
        key={path}
        className={({ isActive }) => (isActive ? cn(style.linkActive, style.link) : style.link)}
        to={path}
      >
        <div className='grid grid-cols-[auto_1fr] items-center max-m595:mt-[9px] max-m595:flex max-m595:flex max-m595:w-[90px] max-m595:flex-col '>
          <CustomIcon className='h-5 w-5 text-[#7A7F85] max-m595:ml-[4px] ' name={icon as string} />

          <span
            className={cn(
              'max-m595:ml-0 max-m595:flex max-m595:justify-center max-m595:text-center max-m595:text-[9px]',
              style.text
            )}
          >
            {title}
          </span>
        </div>

        {hasNotification === 'homework' && (
          <div className={cn('max-m595:top-[12px] max-m595:right-3.5', style.badge)}>
            {messageCountQueryData.data?.data.homeworkCount}
          </div>
        )}
        {hasNotification === 'support' && (
          <div className={cn('max-m595:top-[12px] max-m595:right-3.5', style.badge)}>
            {messageCountQueryData.data?.data.supportCount}
          </div>
        )}
        {hasNotification === 'online-curator' && (
          <div className={cn('max-m595:top-[12px] max-m595:right-3.5', style.badge)}>
            {messageCountQueryData.data?.data.onlineCuratorCount}
          </div>
        )}
        {hasNotification === 'pupil-curator' && (
          <div className={cn('max-m595:top-[12px] max-m595:right-3.5', style.badge)}>
            {messageCountQueryData.data?.data.curatorCount}
          </div>
        )}
        {hasNotification === 'pupil-manager' && (
          <div className={cn('max-m595:top-[12px] max-m595:right-3.5', style.badge)}>
            {messageCountQueryData.data?.data.managerCount}
          </div>
        )}
      </NavLink>
    </Fragment>
  );
};
