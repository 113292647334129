import { ReactNode } from 'react';

import { LoginPageLazy } from '../pages/auth/login-page/login-page.lazy';
import { CourseCreatePageLazy } from '../pages/main/course/course-create-page/course-create-page.lazy';
import { CourseInPageLazy } from '../pages/main/course/course-in-page/course-in-page.lazy';
import { CourseListPageLazy } from '../pages/main/course/course-list-page/course-list-page.lazy';
import { CourseUpdatePageLazy } from '../pages/main/course/course-update-page/course-update-page.lazy';
import { CourseSpeedrunBlockViewLazy } from '../pages/main/course/speedrun/course-speedrun-block-view/course-speedrun-block-view.lazy';
import { CourseSpeedrunViewPageLazy } from '../pages/main/course/speedrun/course-speedrun-view/course-speedrun-view-page.lazy';
import { SpeedrunDirLessonCreatePageLazy } from '../pages/main/course/speedrun/speedrun-dir-create-lesson-page/speedrun-dir-lesson-create-page.lazy';
import { SpeedrunDirUpdateLessonPageLazy } from '../pages/main/course/speedrun/speedrun-dir-update-lesson-page/speedrun-dir-update-lesson-page.lazy';
import { CourseMaterialsCreatePageLazy } from '../pages/main/course-materials-create-page/course-materials-create-page.lazy';
import { CreateInvoicePageLazy } from '../pages/main/create-invoice-page/create-invoice-page.lazy';
import { ReviewsCreatePageLazy } from '../pages/main/create-reviews-page/create-reviews-page.lazy';
import { CuratorConnectPageLazy } from '../pages/main/curator-connect-page/curator-connect-page.lazy';
import { DesktopPageLazy } from '../pages/main/desktop-page/desktop-page.lazy';
import { DiscountsPageLazy } from '../pages/main/discounts-page/discounts-page.lazy';
import { EachNotificationsPageLazy } from '../pages/main/each-notifications-page/each-notifications-page.lazy';
import { EachReviewsPageLazy } from '../pages/main/each-reviews-page/each-reviews-page.lazy';
import { ErrorPageLazy } from '../pages/main/error-page/error-page.lazy';
import { HistoryLogPageLazy } from '../pages/main/history-log-page/history-log-page.lazy';
import { HomeworkAddHomeworkTestPageLazy } from '../pages/main/homework-add-homework-test-page/homework-add-homework-test-page.lazy';
import { HomeworkGroupViewPageLazy } from '../pages/main/homework-group-view-page/homework-group-view-page.lazy';
import { HomeworkPageLazy } from '../pages/main/homework-page/homework-page.lazy';
import { HomeworkTestPageLazy } from '../pages/main/homework-test-page/homework-test-page.lazy';
import { HomeworkViewPageLazy } from '../pages/main/homework-view-page/homework-view-page.lazy';
import { InvoiceChainPageLazy } from '../pages/main/invoice-chain-page/invoice-chain-page.lazy';
import { InvoiceInPageLazy } from '../pages/main/invoice-in-page/invoice-in-page.lazy';
import { InvoicePageLazy } from '../pages/main/invoice-page/invoice-page.lazy';
import { JobBankAskAddTextPageLazy } from '../pages/main/job-bank-ask-add-text-page/job-bank-ask-add-text-page.lazy';
import { JobBankAskCreateFilePageLazy } from '../pages/main/job-bank-ask-create-file-page/job-bank-ask-create-file-page.lazy';
import { JobBankAskCreatePageLazy } from '../pages/main/job-bank-ask-create-page/job-bank-ask-create-page.lazy';
import { JobBankAskPageLazy } from '../pages/main/job-bank-ask-page/job-bank-ask-page.lazy';
import { JobBankAskUpdatePageLazy } from '../pages/main/job-bank-ask-update-page/job-bank-ask-update-page.lazy';
import { JobBankAskViewPageLazy } from '../pages/main/job-bank-ask-view-page/job-bank-ask-view-page.lazy';
import { JobBankMultipleCreatePageLazy } from '../pages/main/job-bank-multiple/job-bank-multiple-create-page/job-bank-multiple-create-page.lazy';
import { JobBankMultipleAddQuestionPageLazy } from '../pages/main/job-bank-multiple-add-question-page/job-bank-multiple-add-question-page.lazy';
import { JobBankMultipleCreateQuestionPageLazy } from '../pages/main/job-bank-multiple-create-question-page/job-bank-multiple-create-question-page.lazy';
import { JobBankMultiplePageLazy } from '../pages/main/job-bank-multiple-page/job-bank-multiple-page.lazy';
import { JobBankPageLazy } from '../pages/main/job-bank-page/job-bank-page.lazy';
import { JobBankTaskPageLazy } from '../pages/main/job-bank-task-page/job-bank-task-page.lazy';
import { JobBankTextAddQuestionPageLazy } from '../pages/main/job-bank-text-add-question-page/job-bank-text-add-question-page.lazy';
import { JobBankTextCreateAudioPage } from '../pages/main/job-bank-text-create-audio-page/job-bank-text-create-audio-page';
import { JobBankTextCreatePageLazy } from '../pages/main/job-bank-text-create-page/job-bank-text-create.lazy';
import { JobBankTextPageLazy } from '../pages/main/job-bank-text-page/job-bank-text-page.lazy';
import { JobBankTextUpdatePageLazy } from '../pages/main/job-bank-text-update-page/job-bank-text-update.lazy';
import { JobBankTextViewPageLazy } from '../pages/main/job-bank-text-view-page/job-bank-text-view.lazy';
import { KnowledgeCreateArticlePageLazy } from '../pages/main/knowledge-create-article-page/knowledge-create-article-page.lazy';
import { KnowledgePageLazy } from '../pages/main/knowledge-page/knowledge-page.lazy';
import { KnowledgeUpdateArticlePageLazy } from '../pages/main/knowledge-update-article-page/knowledge-update-article-page.lazy';
import { KnowledgeViewPageLazy } from '../pages/main/knowledge-view-page/knowledge-view-page.lazy';
import { LessonCreateFileHomeworkPageLazy } from '../pages/main/lesson/lesson-create-file-homework-page/lesson-create-file-homework-page.lazy';
import { LessonCreateFilePageLazy } from '../pages/main/lesson/lesson-create-file-page/lesson-create-file-page.lazy';
import { LessonCreatePageLazy } from '../pages/main/lesson/lesson-create-page/lesson-create-page.lazy';
import { LessonHomeworkCreatePageLazy } from '../pages/main/lesson/lesson-homework-create-page/lesson-homework-create-page.lazy';
import { LessonHomeworkUpdatePageLazy } from '../pages/main/lesson/lesson-homework-update-page/lesson-homework-update-page.lazy';
import { LessonTimeCodeCreatePageLazy } from '../pages/main/lesson/lesson-time-code-create-page/lesson-time-code-create-page.lazy';
import { LessonTimeCodePageLazy } from '../pages/main/lesson/lesson-time-code-page/lesson-time-code-page.lazy';
import { LessonUpdatePageLazy } from '../pages/main/lesson/lesson-update-page/lesson-update-page.lazy';
import { LessonViewPageLazy } from '../pages/main/lesson/lesson-view-page/lesson-view-page.lazy';
import { MenuSectionsPageLazy } from '../pages/main/menu-sections-page/menu-sections-page.lazy';
import { NinetyNinePointsInPageLazy } from '../pages/main/ninety-nine-points-pages/ninety-nine-points-in-page/ninety-nine-points-in-page.lazy';
import { NinetyNinePointsMessagesPageLazy } from '../pages/main/ninety-nine-points-pages/ninety-nine-points-messages-page/ninety-nine-points-messages-page.lazy';
import { NinetyNinePointsPageLazy } from '../pages/main/ninety-nine-points-pages/ninety-nine-points-page/ninety-nine-points-page.lazy';
import { NotificationsPageLazy } from '../pages/main/notifications-page/notifications-page.lazy';
import { OkReviewsPageLazy } from '../pages/main/ok-reviews-page/ok-reviews-page.lazy';
import { OnlineCuratorListPageLazy } from '../pages/main/online-curator-list-page/online-curator-list-page.lazy';
import { OnlineCuratorProfilePageLazy } from '../pages/main/online-curator-profile-page/online-curator-profile-page.lazy';
import { OnlineCuratorViewPageLazy } from '../pages/main/online-curator-view-page/online-curator-view-page.lazy';
import { OpenWebinarsCreatePageLazy } from '../pages/main/open-webinar/open-webinars-create-page/open-webinars-create-page.lazy';
import { OpenWebinarsPageLazy } from '../pages/main/open-webinar/open-webinars-page/open-webinars.lazy';
import { OpenWebinarsUpdatePageLazy } from '../pages/main/open-webinar/open-webinars-update-page/open-webinars-update-page.lazy';
import { OpenWebinarsViewPageLazy } from '../pages/main/open-webinar/open-webinars-view-page/open-webinars-view-page.lazy';
import { OptionsAddQuestionPageLazy } from '../pages/main/options/options-add-question-page/options-add-question-page.lazy';
import { OptionsCreatePageLazy } from '../pages/main/options/options-create-page/options-create-page.lazy';
import { OptionsPageLazy } from '../pages/main/options/options-page/options-page.lazy';
import { OptionsUpdatePageLazy } from '../pages/main/options/options-update-page/options-update-page.lazy';
import { OptionsViewPageLazy } from '../pages/main/options/options-view-page/options-view-page.lazy';
import { PersonalManagerConnectPageLazy } from '../pages/main/personal-manager/personal-manager-connect-page/personal-manager-connect-page.lazy';
import { PersonalManagerProfilePageLazy } from '../pages/main/personal-manager/personal-manager-profile-page/personal-manager-profile-page.lazy';
import { PersonalManagerProfileUpdatePageLazy } from '../pages/main/personal-manager/personal-manager-profile-update-page/personal-manager-profile-update-page.lazy';
import { PracticeListPageLazy } from '../pages/main/practice-list-page/practice-list-page.lazy';
import { PracticeViewPageLazy } from '../pages/main/practice-view-page/practice-view-page.lazy';
import { PresentPageLazy } from '../pages/main/present-page/present-page.lazy';
import { ProfileCuratorPageLazy } from '../pages/main/profile/profile-curator-page/profile-curator-page.lazy';
import { ProfileCuratorUpdatePageLazy } from '../pages/main/profile/profile-curator-update-page/profile-curator-update-page.lazy';
import { ProfileManagerPageLazy } from '../pages/main/profile/profile-manager-page/profile-manager-page.lazy';
import { ProfileManagerUpdatePageLazy } from '../pages/main/profile/profile-manager-update-page/profile-manager-update-page.lazy';
import { ProfilePageLazy } from '../pages/main/profile/profile-page/profile-page.lazy';
import { ProfileRdkPageLazy } from '../pages/main/profile/profile-rdk-page/profile-rdk-page.lazy';
import { ProfileRopmPageLazy } from '../pages/main/profile/profile-ropm-page/profile-ropm-page.lazy';
import { ProfileSeniorCuratorPageLazy } from '../pages/main/profile/profile-senior-curator-page/profile-senior-curator-page.lazy';
import { ProfileTeacherPageLazy } from '../pages/main/profile/profile-teacher-page/profile-teacher-page.lazy';
import { ProfileTeacherUpdatePageLazy } from '../pages/main/profile/profile-teacher-update-page/profile-teacher-update-page.lazy';
import { ProfileTeamLeadPageLazy } from '../pages/main/profile/profile-team-lead-page/profile-team-lead-page.lazy';
import { ProfileUpdatePageLazy } from '../pages/main/profile/profile-update-page/profile-update-page.lazy';
import { PupilsCuratorInPageLazy } from '../pages/main/pupils-curator-in-page/pupils-curator-in-page.lazy';
import { PupilsCuratorPageLazy } from '../pages/main/pupils-curator-page/pupils-curator-page.lazy';
import { PupilsManagerInPageLazy } from '../pages/main/pupils-manager-in-page/pupils-manager-in-page.lazy';
import { PupilsManagerPageLazy } from '../pages/main/pupils-manager-page/pupils-manager-page.lazy';
import { RedirectPageLazy } from '../pages/main/redirects/redirect-page/redirect-page.lazy';
import { ReviewsPageLazy } from '../pages/main/reviews-page/reviews-page.lazy';
import { ReviewsUpdatePageLazy } from '../pages/main/reviews-update-page/reviews-update-page.lazy';
import { RopConnectPageLazy } from '../pages/main/rop-connect-page/rop-connect-page.lazy';
import { RopmConnectPageLazy } from '../pages/main/ropm-connect-page/ropm-connect-page.lazy';
import { StatisticsPageLazy } from '../pages/main/statistics-page/statistics-page.lazy';
import { SupportInPageLazy } from '../pages/main/support/support-in-page/support-in-page.lazy';
import { SupportPageLazy } from '../pages/main/support/support-page/support-page.lazy';
import { SupportRobotPageLazy } from '../pages/main/support-robot-page/support-robot-page.lazy';
import { TagsPageLazy } from '../pages/main/tags-page/tags-page.lazy';
import { TeacherAddRewardPageLazy } from '../pages/main/teacher-add-reward-page/teacher-add-reward-page.lazy';
import { TelegramPageLazy } from '../pages/main/telegram-page/telegram-page.lazy';
import { UserListPageLazy } from '../pages/main/user-list-page/user-list-page.lazy';

export interface RouteProps {
  component: ReactNode;
  path: string;
  hasNotification?: 'homework' | 'support' | 'online-curator' | 'pupil-curator' | 'pupil-manager';
  icon?: string;
  roleAccess?: string[];
  title?: string;
}

export const LEFT_MENU: RouteProps[] = [
  {
    component: <DesktopPageLazy />,
    icon: 'rr-home',
    path: '/',
    roleAccess: ['site', 'index'],
    title: 'Рабочий стол',
  },
  {
    component: <UserListPageLazy />,
    icon: 'rr-users',
    path: '/users',
    roleAccess: ['user', 'index'],
    title: 'Пользователи',
  },
  {
    component: <CourseListPageLazy />,
    icon: 'rr-courses',
    path: '/course',
    roleAccess: ['course', 'index'],
    title: 'Курсы/Мастер-группы',
  },
  {
    component: <OpenWebinarsPageLazy />,
    icon: 'rr-books',
    path: '/lesson/open-webinars',
    roleAccess: ['open-webinar', 'index'],
    title: 'Открытые вебинары',
  },
  {
    component: <HomeworkPageLazy />,
    hasNotification: 'homework',
    icon: 'rr-homework',
    path: '/user-home-work',
    roleAccess: ['user-home-work', 'index'],
    title: 'Домашние задания',
  },
  {
    component: <CuratorConnectPageLazy />,
    icon: 'rr-bind',
    path: '/user-curator',
    roleAccess: ['user-curator', 'index'],
    title: 'Связи учеников с кураторами',
  },
  {
    component: <PersonalManagerConnectPageLazy />,
    icon: 'rr-bind',
    path: '/user-manager',
    roleAccess: ['user-manager', 'index'],
    title: 'Связи учеников с ПМ',
  },
  {
    component: <RopmConnectPageLazy />,
    icon: 'rr-bind',
    path: '/user-ropm',
    roleAccess: ['user-ropm', 'index'],
    title: 'Связи РОПМ с ПМ',
  },
  {
    component: <RopConnectPageLazy />,
    icon: 'rr-bind',
    path: '/rop-ropm',
    roleAccess: ['rop-ropm', 'index'],
    title: 'Связи РОП и менеджерами',
  },
  {
    component: <KnowledgePageLazy />,
    icon: 'rr-info',
    path: '/knowledge',
    roleAccess: ['knowledge', 'view-text'],
    title: 'База знаний',
  },
  {
    component: <ReviewsPageLazy />,
    icon: 'rr-star',
    path: '/reviews',
    roleAccess: ['reviews', 'index'],
    title: 'Отзывы',
  },
  {
    component: <OkReviewsPageLazy />,
    icon: 'review-star',
    path: '/ok-reviews',
    roleAccess: ['review-online-curator', 'index'],
    title: 'Отзывы ОК',
  },
  {
    component: <SupportPageLazy />,
    hasNotification: 'support',
    icon: 'rr-support',
    path: '/support',
    roleAccess: ['support', 'index'],
    title: 'Техподдержка',
  },
  {
    component: <SupportRobotPageLazy />,
    icon: 'rr-support',
    path: '/support-robot',
    roleAccess: ['support-robot', 'index'],
    title: 'Робот техподдержки',
  },
  {
    component: <NinetyNinePointsPageLazy />,
    icon: 'rr-support',
    path: '/ninety-nine-points',
    roleAccess: ['ninety-nine-points', 'index'],
    title: '99 Баллов',
  },
  {
    component: <InvoicePageLazy />,
    icon: 'rr-invoice',
    path: '/invoice',
    roleAccess: ['invoice', 'index'],
    title: 'Выставление счетов',
  },
  {
    component: <PresentPageLazy />,
    icon: 'rr-presents',
    path: '/present',
    roleAccess: ['present', 'index'],
    title: 'Подарки',
  },
  {
    component: <DiscountsPageLazy />,
    icon: 'rr-presents',
    path: '/discounts',
    roleAccess: ['discount', 'view-discount'],
    title: 'Скидки и промокоды',
  },
  {
    component: <JobBankPageLazy />,
    icon: 'rr-bank',
    path: '/job-bank',
    roleAccess: ['job-bank-theme', 'index'],
    title: 'Банк заданий',
  },
  {
    component: <MenuSectionsPageLazy />,
    icon: 'rr-list',
    path: '/menu',
    roleAccess: ['menu', 'index'],
    title: 'Разделы меню',
  },
  {
    component: <TagsPageLazy />,
    icon: 'rr-tag',
    path: '/tags',
    roleAccess: ['tag', 'index'],
    title: 'Тэги',
  },
  {
    component: <PupilsCuratorPageLazy />,
    icon: 'rr-bind',
    hasNotification: 'pupil-curator',
    path: '/user-curator-pupils',
    roleAccess: ['user-curator', 'pupils'],
    title: 'Мои ученики',
  },
  {
    component: <PupilsManagerPageLazy />,
    icon: 'rr-bind',
    hasNotification: 'pupil-manager',
    path: '/manager-pupils',
    roleAccess: ['user-manager', 'pupils'],
    title: 'Мои ученики',
  },
  {
    component: <OnlineCuratorListPageLazy />,
    hasNotification: 'online-curator',
    icon: 'rr-online-curator',
    path: '/online-curator',
    roleAccess: ['online-curator', 'index'],
    title: 'Онлайн-куратор',
  },
  {
    component: <PracticeListPageLazy />,
    icon: 'rr-pencil-rule',
    path: '/practice',
    roleAccess: ['practice', 'index'],
    title: 'Практика',
  },
  {
    component: <OptionsPageLazy />,
    icon: 'sr-options',
    path: '/options',
    roleAccess: ['options', 'index'],
    title: 'Варианты',
  },
  {
    component: <RedirectPageLazy />,
    icon: 'refresh_arrows',
    path: '/redirects',
    roleAccess: ['route', 'index'],
    title: 'Настройка редиректов',
  },
];

export const MAIN_ROUTES: RouteProps[] = [
  ...LEFT_MENU,
  {
    component: <ProfilePageLazy />,
    path: '/profile/:id',
  },
  {
    component: <ProfilePageLazy />,
    path: '/users/:id',
  },
  {
    component: <ProfileUpdatePageLazy />,
    path: '/users/update/:id',
  },
  {
    component: <ProfileUpdatePageLazy />,
    path: '/profile/update/:id',
  },
  {
    component: <ProfileTeacherPageLazy />,
    path: '/users/teacher-profile/:id',
  },
  {
    component: <ProfileTeacherUpdatePageLazy />,
    path: '/users/teacher-update/:id',
  },
  {
    component: <TeacherAddRewardPageLazy />,
    path: '/users/teacher/add-reward/:id',
  },
  {
    component: <ProfileCuratorPageLazy />,
    path: '/users/curator-profile/:id',
  },
  {
    component: <ProfileCuratorUpdatePageLazy />,
    path: '/users/curator-update/:id',
  },
  {
    component: <PersonalManagerProfilePageLazy />,
    path: '/users/manager-profile/:id',
  },
  {
    component: <PersonalManagerProfileUpdatePageLazy />,
    path: '/users/manager-update/:id',
  },
  {
    component: <ProfileSeniorCuratorPageLazy />,
    path: '/users/senior-curator-profile/:id',
  },
  {
    component: <ProfileRdkPageLazy />,
    path: '/users/rdk-profile/:id',
  },
  {
    component: <ProfileRopmPageLazy />,
    path: '/users/ropm-profile/:id',
  },
  {
    component: <OnlineCuratorProfilePageLazy />,
    path: '/users/online-curator-profile/:id',
  },
  {
    component: <ProfileManagerPageLazy />,
    path: '/users/manager-user-profile/:id',
  },
  {
    component: <ProfileManagerUpdatePageLazy />,
    path: '/users/manager-user-update/:id',
  },
  {
    component: <ProfileTeamLeadPageLazy />,
    path: '/users/team-lead-profile/:id',
  },
  {
    component: <HistoryLogPageLazy />,
    path: '/users/log/:id',
  },
  {
    component: <StatisticsPageLazy />,
    path: '/statistics/:id',
  },
  {
    component: <KnowledgeViewPageLazy />,
    path: '/knowledge/:id',
  },
  {
    component: <KnowledgeCreateArticlePageLazy />,
    path: '/knowledge-create-article',
  },
  {
    component: <KnowledgeUpdateArticlePageLazy />,
    path: '/knowledge-update-article/:id',
  },
  {
    component: <NotificationsPageLazy />,
    path: '/notifications',
  },
  {
    component: <EachNotificationsPageLazy />,
    path: '/notifications/:id',
  },
  {
    component: <TelegramPageLazy />,
    path: '/telegram',
  },
  {
    component: <EachReviewsPageLazy />,
    path: '/reviews/:id',
  },
  {
    component: <ReviewsCreatePageLazy />,
    path: '/reviews/create',
  },
  {
    component: <ReviewsUpdatePageLazy />,
    path: '/reviews/edit/:id',
  },
  {
    component: <PupilsCuratorInPageLazy />,
    path: '/user-curator-pupils/:id',
  },
  {
    component: <PupilsManagerInPageLazy />,
    path: '/manager-pupils/:id',
  },
  {
    component: <CourseInPageLazy />,
    path: '/course/:id',
  },
  {
    component: <CourseUpdatePageLazy />,
    path: '/course/update/:id',
  },
  {
    component: <CourseCreatePageLazy />,
    path: '/course/create',
  },
  {
    component: <SupportInPageLazy />,
    path: '/support/:id',
  },
  {
    component: <NinetyNinePointsInPageLazy />,
    path: '/ninety-nine-points/:id',
  },
  {
    component: <InvoiceInPageLazy />,
    path: '/invoice/:id',
  },
  {
    component: <CreateInvoicePageLazy />,
    path: '/invoice/create',
  },
  {
    component: <HomeworkViewPageLazy />,
    path: '/user-home-work/:id',
  },
  {
    component: <HomeworkGroupViewPageLazy />,
    path: '/homework/:id',
  },
  {
    component: <JobBankAskPageLazy />,
    path: '/job-bank-ask/:id',
  },
  {
    component: <JobBankAskAddTextPageLazy />,
    path: '/job-bank-ask/add-text/:id',
  },
  {
    component: <JobBankAskCreatePageLazy />,
    path: '/job-bank-ask/create/:id',
  },
  {
    component: <JobBankAskUpdatePageLazy />,
    path: '/job-bank-ask/update/:id',
  },
  {
    component: <JobBankAskViewPageLazy />,
    path: '/job-bank-ask-view/:id',
  },
  {
    component: <JobBankAskCreateFilePageLazy />,
    path: '/job-bank-ask/create-file/:id',
  },
  {
    component: <JobBankTextPageLazy />,
    path: '/job-bank-text/:id',
  },
  {
    component: <JobBankTextViewPageLazy />,
    path: '/job-bank-text-view/:id',
  },
  {
    component: <JobBankTextCreatePageLazy />,
    path: '/job-bank/text/create/:id',
  },
  {
    component: <JobBankTextCreateAudioPage />,
    path: '/job-bank/text/create-audio/:id',
  },
  {
    component: <JobBankTextAddQuestionPageLazy />,
    path: '/job-bank-text/add-question/:id',
  },
  {
    component: <JobBankMultipleAddQuestionPageLazy />,
    path: '/job-bank-multi-task/add-question/:id',
  },
  {
    component: <JobBankMultipleCreateQuestionPageLazy />,
    path: '/job-bank-multi-task/create-question/:id',
  },
  {
    component: <JobBankTextUpdatePageLazy />,
    path: '/job-bank-text/update/:id',
  },
  {
    component: <JobBankTaskPageLazy />,
    path: '/job-bank-task/:id',
  },
  {
    component: <InvoiceChainPageLazy />,
    path: '/invoice-chain/:id',
  },
  {
    component: <LessonCreatePageLazy />,
    path: '/lesson/create/:id',
  },
  {
    component: <OpenWebinarsCreatePageLazy />,
    path: '/lesson/open-webinars/create',
  },
  {
    component: <OpenWebinarsViewPageLazy />,
    path: '/lesson/open-webinars/view/:id',
  },
  {
    component: <OpenWebinarsUpdatePageLazy />,
    path: '/lesson/open-webinars/update/:id',
  },
  {
    component: <LessonUpdatePageLazy />,
    path: '/lesson/update/:id',
  },
  {
    component: <LessonViewPageLazy />,
    path: '/lesson/:id',
  },
  {
    component: <CourseMaterialsCreatePageLazy />,
    path: '/create-file-course/:id',
  },
  {
    component: <LessonTimeCodeCreatePageLazy />,
    path: '/lesson/time-code-create/:id',
  },
  {
    component: <LessonTimeCodeCreatePageLazy />,
    path: '/lesson/open-webinar/time-code-create/:id',
  },
  {
    component: <LessonTimeCodePageLazy />,
    path: '/lesson/time-code/:id',
  },
  {
    component: <LessonTimeCodePageLazy />,
    path: '/lesson/open-webinar/time-code/:id',
  },
  {
    component: <LessonHomeworkCreatePageLazy />,
    path: '/homework/create/:id',
  },
  {
    component: <LessonHomeworkUpdatePageLazy />,
    path: '/homework/update/:id',
  },
  {
    component: <HomeworkAddHomeworkTestPageLazy />,
    path: '/homework/add-homework-test/:id',
  },
  {
    component: <LessonCreateFilePageLazy />,
    path: '/lesson/create-file/:id',
  },
  {
    component: <LessonCreateFilePageLazy />,
    path: '/lesson/open-webinar/create-file/:id',
  },
  {
    component: <LessonCreateFileHomeworkPageLazy />,
    path: '/lesson/create-file-homework/:id',
  },
  {
    component: <OnlineCuratorViewPageLazy />,
    path: '/online-curator/:id',
  },
  {
    component: <PracticeViewPageLazy />,
    path: '/practice/:id',
  },
  {
    component: <HomeworkTestPageLazy />,
    path: '/user-home-work/test/:id',
  },
  {
    component: <OptionsViewPageLazy />,
    path: '/options/view/:id',
  },
  {
    component: <OptionsCreatePageLazy />,
    path: '/options/create',
  },
  {
    component: <OptionsUpdatePageLazy />,
    path: '/options/update/:id',
  },
  {
    component: <OptionsAddQuestionPageLazy />,
    path: '/options/add-question/:id',
  },
  {
    component: <JobBankMultipleCreatePageLazy />,
    path: '/job-bank-multi-task/create',
  },
  {
    component: <JobBankMultiplePageLazy />,
    path: '/job-bank-multi-task/:id',
  },
  {
    component: <NinetyNinePointsMessagesPageLazy />,
    path: '/ninety-nine-points/messages',
  },
  {
    component: <CourseSpeedrunViewPageLazy />,
    path: '/course/speedrun/:id',
  },
  {
    component: <CourseSpeedrunBlockViewLazy />,
    path: '/course/block/:id',
  },
  {
    component: <SpeedrunDirLessonCreatePageLazy />,
    path: '/course/block/create-lesson/:id',
  },
  {
    component: <SpeedrunDirUpdateLessonPageLazy />,
    path: '/course/block/update-lesson/:id/:lessonId',
  },
  {
    component: <ErrorPageLazy />,
    path: '/403',
  },
  {
    component: <ErrorPageLazy />,
    path: '/404',
  },
];

export const AUTH_ROUTES: RouteProps[] = [
  {
    component: <LoginPageLazy />,
    path: '/login',
  },
  {
    component: <ErrorPageLazy />,
    path: '/403',
  },
];
