import { ChangeEvent, HTMLProps, useEffect, useId, useRef } from 'react';

import cn from 'classnames';
import { AsYouType } from 'libphonenumber-js';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

import { CustomIcon } from '../../custom-icon/custom-icon';
import { InputError } from '../common/input-error/input-error';
import { InputLabel } from '../common/input-label/input-label';

export interface CustomInputProps extends HTMLProps<HTMLInputElement> {
  name: string;
  allowClear?: boolean;
  errorClassName?: HTMLDivElement['className'];
  handleChange?: (name: string, value: string) => void;
  label?: string;
  prefix?: string;
  rules?: RegisterOptions;
  withArrayForm?: boolean;
  wrapCn?: HTMLDivElement['className'];
}

export const INPUT_CLASSNAME =
  'h-9 w-full min-w-[85px] rounded-lg border border-input-border !bg-white px-3 text-sm shadow-none outline-0 hover:border-input-border2 focus:border-primary';

export const CustomInput = ({
  label,
  errorClassName,
  rules,
  name,
  handleChange,
  className,
  type,
  withArrayForm,
  prefix,
  wrapCn,
  allowClear = false,
  ...props
}: CustomInputProps) => {
  const { control } = useFormContext();
  const id = useId();
  const ref = useRef<HTMLInputElement>(null);
  const prefixRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (prefix && prefixRef.current && ref.current) {
      ref.current.style.paddingLeft = `${prefixRef.current.getBoundingClientRect().width + 12}px`;
    }
  }, [prefixRef, ref, prefix]);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange }, fieldState }) => {
        const isClearIconVisible = allowClear && !!value?.length;
        const isPhone = type === 'tel';

        const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target;

          handleChange?.(name, value);
          onChange(value);
        };

        const resetField = () => {
          handleChange?.(name, '');
          onChange('');
        };

        return (
          <div className={cn('space-y-1', wrapCn)}>
            <InputLabel htmlFor={id}>{label}</InputLabel>

            <div className='relative'>
              {prefix && (
                <span ref={prefixRef} className='pointer-events-none left-3 text-sm text-[#D3D9E0] pos-abs-y'>
                  {prefix}
                </span>
              )}
              <input
                ref={ref}
                defaultValue={prefix}
                id={id}
                onChange={props.onChange || handleInputChange}
                type={type}
                value={isPhone ? new AsYouType().input(value) : value || ''}
                className={cn(
                  'h-9 w-full min-w-[85px] rounded-lg border border-input-border !bg-white px-3 text-sm shadow-none outline-0 hover:border-input-border2 focus:border-primary',
                  { 'pr-6': isClearIconVisible },
                  { 'cursor-default text-gray hover:border-input-border': props.disabled },
                  className
                )}
                {...props}
              />

              {isClearIconVisible && (
                <CustomIcon
                  className='absolute top-1/2 right-2 h-3 w-3 -translate-y-1/2 cursor-pointer text-gray hover:text-dark'
                  name='sr-cross'
                  onClick={resetField}
                />
              )}
            </div>

            <InputError
              {...(withArrayForm && { errorStr: fieldState.error?.message })}
              errorClassName={errorClassName}
              name={name}
            />
          </div>
        );
      }}
    />
  );
};
